import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function SidebarSubHeader({ children }) {
  return <div className="sidebar-sub-header">{children}</div>;
}

SidebarSubHeader.propTypes = propTypes;
SidebarSubHeader.defaultProps = defaultProps;

export default SidebarSubHeader;
