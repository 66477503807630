import type React from 'react';

type SidebarFooterProps = {
  children: React.ReactNode;
};

function SidebarFooter({ children = null }: SidebarFooterProps): React.JSX.Element {
  return (
    <div className="sidebar-footer col-xs-12 space-top-3 space-3 bg-catskill-white">{children}</div>
  );
}

export default SidebarFooter;
