import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import SidebarContent from './SidebarContent';
import SidebarHeader from './SidebarHeader';
import SidebarFooter from './SidebarFooter';
import SidebarSubHeader from './SidebarSubHeader';
import useSidebars from '@/sidebars/hooks/useSidebars';

type SidebarProps = {
  id: string;
  className?: string;
  children: React.ReactNode;
  size?: 'lg' | 'md' | 'sm';
  container?: string;
  zIndex?: number;
  isShowBackdrop?: boolean;
};

function Sidebar({
  id,
  children,
  className = '',
  size = 'md',
  container = null,
  zIndex = 1002,
  isShowBackdrop = true,
}: SidebarProps): React.JSX.Element {
  // Hooks
  const sidebars = useSidebars();
  const [topOffset, setTopOffset] = useState(getContainerOffset());

  // Variables
  const index = sidebars.data.findIndex((s) => s.id === id);
  const animationDuration = 0.2;

  // Functions
  function getContainerOffset() {
    const containerNode = document.querySelector(container) as HTMLElement;

    return containerNode?.offsetTop || 0;
  }

  // Effects
  useEffect(() => {
    if (!window.ResizeObserver) return () => {};

    const resizeObserver = new ResizeObserver(() => {
      setTopOffset(getContainerOffset());
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  // Render
  return (
    <>
      {isShowBackdrop && (
        <motion.div
          data-testid="sidebar-backdrop"
          key={`sidebar-backdrop-${id}`}
          className="sidebar-backdrop"
          style={{
            top: topOffset,
            zIndex,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 0.5,
            transition: {
              duration: animationDuration,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: animationDuration,
            },
          }}
        />
      )}
      <motion.div
        key={`sidebar-component-${id}`}
        className="sidebar-container"
        style={{
          top: topOffset,
          zIndex,
        }}
        initial={{
          x: '100%',
        }}
        animate={{
          x: -((sidebars.data.length - 1) * 80 - index * 80),
          transition: {
            duration: animationDuration,
          },
        }}
        exit={{
          x: '100%',
          transition: {
            duration: animationDuration,
          },
        }}
      >
        <div
          data-testid="sidebar"
          data-intercom-target={id}
          className={classNames('sidebar shadow-lg', className, `sidebar--${size}`, {
            'sidebar--with-backdrop': isShowBackdrop,
          })}
        >
          {children}
        </div>
      </motion.div>
    </>
  );
}

Sidebar.Header = SidebarHeader;
Sidebar.Footer = SidebarFooter;
Sidebar.SubHeader = SidebarSubHeader;
Sidebar.Content = SidebarContent;

export default Sidebar;
