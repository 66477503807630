import React, { useState } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Icon } from '@iconify-icon/react';
import useSidebars from '@/sidebars/hooks/useSidebars';
import OverlayTooltip from '@/storychief/components/OverlayTooltip';
import Button from '@/storychief/components/Button';
import SwitchButton from '@/storychief/components/SwitchButton';

type SidebarHeaderProps = {
  title: React.ReactNode;
  sidebarId: string;
  children?: React.ReactNode;
  subtitle?: string;
  isDirty?: boolean;
  onSave?: () => void;
  onSaveLoading?: boolean;
  onClose?: () => Promise<boolean> | boolean | void;
  onFollow?: () => void;
  onExtraButtonClick?: () => void;
  isFollowing?: boolean;
  isProcessingFollowing?: boolean;
  searchLoading?: boolean;
  searchQuery?: string;
  buttonLabel?: string;
  extraButtonLabel?: React.ReactNode;
  extraButtonIsDisabled?: boolean;
  setActiveQuery?: (query: string) => void;
};

function SidebarHeader({
  children = null,
  title,
  subtitle = null,
  sidebarId,
  setActiveQuery = null,
  onSave = null,
  onSaveLoading = false,
  onClose = () => true,
  isDirty = false,
  buttonLabel = 'Save',
  extraButtonLabel = 'Upload',
  extraButtonIsDisabled = false,
  searchLoading = false,
  searchQuery = '',
  onFollow = null,
  onExtraButtonClick = null,
  isFollowing = false,
  isProcessingFollowing = false,
}: SidebarHeaderProps): React.JSX.Element {
  // State
  const [showSearchInput, setShowSearchInput] = useState(false);

  // Hooks
  const sidebars = useSidebars();

  // Functions
  async function handleOnClose() {
    if ((await onClose()) !== false) {
      sidebars.toggle(sidebarId);
    }
  }

  function hideSearchInput() {
    setActiveQuery('');
    setShowSearchInput(false);
  }

  function showInput() {
    setShowSearchInput(true);
  }

  return (
    <div className="sidebar-header col-xs-12 clearfix py-3">
      <div className="row-flex flex-align-center">
        <div className="sidebar-header-title col-flex flex-align-center flex-grow">
          <h2 className="h5 m-0">
            {title}
            {subtitle && <div className="small">{subtitle}</div>}
          </h2>
        </div>
        {onExtraButtonClick && (
          <div className="col-flex gutter-2 flex-align-center">
            <button
              type="button"
              className="btn btn-link btn-small"
              onClick={onExtraButtonClick}
              disabled={extraButtonIsDisabled}
            >
              {extraButtonLabel}
            </button>
          </div>
        )}
        {onSave && (
          <div className="col-flex flex-align-center">
            <Button
              disabled={!isDirty || onSaveLoading}
              aria-label={buttonLabel}
              onClick={onSave}
              size="sm"
            >
              {onSaveLoading && (
                <span className="animated-spin d-inline-block">
                  <span className="icon-spin1" />
                </span>
              )}
              {buttonLabel}
            </Button>
          </div>
        )}
        {onFollow && (
          <div className="d-flex flex-gap-sm flex-align-items-center gutter-right-2">
            <SwitchButton
              isActive={isFollowing}
              onChange={onFollow}
              disabled={isProcessingFollowing}
              size="xsmall"
            />
            <span className="text-muted small">{buttonLabel}</span>
          </div>
        )}
        {setActiveQuery && (
          <div className="col-flex flex-align-center">
            {showSearchInput ? (
              <Form
                inline
                onSubmit={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {searchLoading && (
                  <span className="d-inline-block">
                    <span className="animated-spin d-inline-block">
                      <span className="icon-spin1" />
                    </span>
                  </span>
                )}
                <FormGroup bsSize="small" controlId="query" className="form-group--search">
                  <FormControl
                    type="text"
                    placeholder="Quick search"
                    name="query"
                    value={searchQuery}
                    onChange={(e) => {
                      setActiveQuery((e.target as HTMLInputElement).value);
                    }}
                    autoFocus
                  />
                  <button
                    type="button"
                    className="btn btn-chromeless icon-cancel"
                    aria-hidden="true"
                    onClick={hideSearchInput}
                  />
                </FormGroup>
              </Form>
            ) : (
              <OverlayTooltip placement="bottom" content="Search">
                <Button variant="minimal" size="sm-square" aria-label="Search" onClick={showInput}>
                  <Icon icon="fa:search" />
                </Button>
              </OverlayTooltip>
            )}
          </div>
        )}
        {children}
        <div className="col-flex flex-align-center">
          <OverlayTooltip id="sidebar-close" placement="bottom" content="Close">
            <Button
              variant="minimal"
              size="sm-square"
              aria-label="Close"
              onClick={handleOnClose}
              style={{ fontSize: 14 }}
            >
              <em className="icon-cancel-2" />
            </Button>
          </OverlayTooltip>
        </div>
      </div>
    </div>
  );
}

export default SidebarHeader;
