import type React from 'react';
import classNames from 'classnames';
import ErrorBoundary from '@/storychief/components/ErrorBoundary';

type SidebarProps = {
  children: React.ReactNode;
  noSpacing?: boolean;
  noGutter?: boolean;
  className?: string;
};

function SidebarContent({
  children,
  className = null,
  noSpacing = false,
  noGutter = false,
}: SidebarProps): React.JSX.Element {
  const sidebarInnerContentClass = classNames('sidebar-content-scroll', {
    'space-3 space-top-3': !noSpacing,
    'gutter-3': !noGutter,
  });

  return (
    <div className={classNames(['sidebar-content', className])}>
      <div className={sidebarInnerContentClass} id="sidebar-content-scroll-wrapper">
        <ErrorBoundary className="space-top-4 space-4">{children}</ErrorBoundary>
      </div>
    </div>
  );
}

export default SidebarContent;
